import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO siteTitle="404" />
    <StyledFour>
      <p>Oh no! This page doesn't exist.</p>
    </StyledFour>
  </Layout>
);

const StyledFour = styled.section`
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default NotFoundPage;
